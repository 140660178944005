import type { LinkProps } from '@remix-run/react';
import { Link } from '@remix-run/react';
import type React from 'react';
import {Button, ButtonMode, ButtonProps} from "./Button";
import {clsx} from "../../utils/styles";

export interface PrimaryLinkProps extends LinkProps {
	mode?: ButtonMode;
	btnProps?: Partial<ButtonProps>;
}

export const LinkButton: React.FC<PrimaryLinkProps> = (props) => {
	const { mode, to, children, className, btnProps, ...rest } = props;
	const classes = clsx('flex-1', className);
	return (
		<Link to={to} className={clsx('flex', { 'pointer-events-none': btnProps?.disabled })} {...rest}>
			<Button
				className={classes}
				mode={mode}
				// TODO: is this needed?
				// onClick={(e) => e.stopPropagation()}
				{...btnProps}
			>
				{children}
			</Button>
		</Link>
	);
};
